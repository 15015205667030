const statWinnerHigh = (m, r, x = 'highWins') => {

  m = Number(m)
  r = Number(r)

  if(x === 'lowWins') {
    if(m !== 0 && m < r) {
      return 'm'
    } else if (m === r) {
      return 'd'
    } else if (r === '-' && m !== '-') {
      return 'm'
    } else if (m === '-' && r !== '-') {
      return 'r'
    } else if(r !== 0 && r < m) {
      return 'r'
    } else if(m === 0 && r !== 0) {
      return 'r'
    } else if(m !== 0 && r === 0) {
      return 'm'
    }
  } else {
    if(m > r) {
      return 'm'
    } else if (m === r) {
      return 'd'
    } else if (r === '-' && m !== '-') {
      return 'm'
    } else if (m === '-' && r !== '-') {
      return 'r'
    } else {
      return 'r'
    }
  }
  
}

export default statWinnerHigh